body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  margin: 15px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.slick-custom-dots {
  position: relative;
  text-align: center;
  font-size: 1.2em;
}

.slick-custom-active {
  color: #19b1c9;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}
.navbar-light .navbar-nav .monastays_nav_link:hover {
  color: #e2a915;
}
.translate_link .badge img {
  width: 17px;
}
.floatingBooking {
  position: sticky;
  position: -webkit-sticky;
  top: 10px;
}
.gm-style-iw.gm-style-iw-c,
.gm-style-iw-d {
  max-height: 500px !important;
}
.gm-style-iw-d .price_card_location {
  margin-left: 14px;
}
.suites_title_block {
  max-width: 220px;
}
.suites_price_block > span {
  font-size: 12px;
}
.suites_remove {
  width: 15px;
  height: 15px;
}
.price_info_icons {
  display: flex;
  align-items: center;
}
.suites_header {
  flex-wrap: wrap;
}
.suites_price_block {
  text-align: right;
}
.show_filter {
  margin-right: 10px;
}
.search_map_sline {
  margin-top: 20px;
}
.search_map_sline_hide {
  display: none !important;
}
form.monastays_search_map_form {
  flex-wrap: wrap;
  max-width: 700px;
}
.search_map_fline,
.search_map_sline {
  width: 100%;
}
.suites_price_block {
  width: 120px;
}
.DateRangePicker_picker {
  z-index: 200;
}
.meal_options_container {
  margin-top: 32px;
}
.meal_options_container .edit_title_h4 {
  margin-bottom: 20px;
}
.popular_check_label .edit_check_price {
  margin-left: 11px;
  text-transform: uppercase;
  font-size: 10px;
}
.booking_cancel_btn {
  background: #fff;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495766;
}
.booking_apply_btn {
  padding: 16px 48px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #ffffff;
}
.booking_cancel_btn:hover {
  opacity: 0.8;
}
.booking_edit_btn_wrapper {
  justify-content: space-between;
}
.suites_add_btn {
  border-radius: 3px;
  border: solid 1.5px #18b1c9;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #18b1c9;
  padding: 8px 15px 9px 15px;
  background: #fff;
}
.suites_add_btn:hover {
  opacity: 0.8;
}
.edit_dates_wrapper .popular_input_wrapper {
  margin-right: 20px;
}
.edit_booking_inputs_wrapper {
  margin-bottom: 20px;
}

.edit_btn_wrapper {
  align-items: center;
  margin-bottom: 10px;
}
.edit_btn_circle_black {
  width: 20px;
  height: 20px;
  background-image: url(/img/arrow-left-circle.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.edit_btn_circle_black:hover {
  opacity: 0.8;
}
.edit_btn_circle_aqua {
  width: 33px;
  height: 33px;
  background-size: contain;
  background-image: url(/img/arrow-left-circle_aqua.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
}
.suites_edit_btn_wrapper {
  justify-content: space-between;
  align-items: center;
}
.edit_title_h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.16;
  letter-spacing: 0.3px;
  color: #325078;
}
.edit_btn_circle_aqua:hover {
  opacity: 0.8;
}

.booking_edit_btn {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #19b1c9;
  padding: 9px 9px 9px 30px;
  background-color: #fff;
  border-radius: 6px;
  border: solid 1.5px #19b1c9;
  background-image: url(/img/edit.svg);
  background-size: 16px;
  background-position: 9px;
  background-repeat: no-repeat;
}

.booking_edit_btn:hover {
  color: #e2a915;
  border-color: #e2a915;
  text-decoration: none;
  background-image: url(/img/edit-orange.svg);
}

.booking_edit_subtitile {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;

  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: normal;
  font-weight: normal;
  color: #325078;
}
.booking_range_card {
  border-radius: 3px;
  border: solid 1px #f1f2f9;
  background-color: #ffffff;
  width: 146px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 14px;
}
.booking_range_day {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2b313d;
  margin-bottom: 8px;
}
.booking_range_data {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: #325078;
  margin-bottom: 11px;
}
.booking_range_time {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  text-align: center;
  color: #60748a;
  margin-bottom: 0;
}
.booking_check .popular_check_label {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #60748a;
}
.booking_btn_wrapper .booking_pay_darken_btn {
  margin-left: 12px;
  background-color: #19b1c9;
}
.booking_btn_wrapper {
  margin-top: 20px;
  align-self: center;
  flex-wrap: wrap;
}

.booking_pay_darken_btn:hover {
  background-color: #e2a915;
}
.booking_btn_wrapper .booking_pay_btn {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  width: 224px;
  margin: 10px;
}
.booking_range_card_icon {
  margin-bottom: 13px;
  width: 22px;
  height: 20px;
}
.booking_range_arrow {
  width: 42px;
  margin-left: 21px;
  margin-right: 21px;
}
.booking_range_wrapper {
  margin-bottom: 33px;
}
.booking_edit_p {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: #60748a;
  margin-bottom: 0;
}
.booking_readmore_link {
  margin-top: 10px;
}
.booking_edit_title {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.16;
  letter-spacing: 0.1px;
  color: #325078;
}
.edit_booking_wrapper {
  justify-content: space-between;
  flex-wrap: wrap;
}

.monastays_info_h5 {
  text-decoration: underline;
  font-weight: normal;
}
.monastays_info_ol {
  list-style-type: lower-latin;
}
.contact_form_container {
  /*background: #4572c4;*/

  font-family: 'Montserrat', sans-serif;
  width: 400px;
}
.contact_form_p {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
}

@media screen and (max-width: 800px) {
  .default_button_width {
    font-family: 'Montserrat', sans-serif;
    max-width: 280px;
  }
}
@media screen and (min-width: 801px) {
  .default_button_width {
    font-family: 'Montserrat', sans-serif;
    max-width: 400px;
  }
}

.monastays_info_icon {
  max-width: 150px;
}
.contact_info_mail {
  color: rgb(0, 106, 133);
  text-decoration: underline;
}
.booking_search {
  max-width: 401px;
  margin-left: auto;
}
.booking_wrapper {
  border-bottom: solid 1px #f1f2f9;
  padding-bottom: 10px;
  padding-top: 23px;
}
.booking_room_icon {
  width: 17px;
  height: 17px;
  margin-right: 20px;
}
.booking_room_text,
.booking_room_price {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.1px;
  color: #60748a;
}
.booking_room_price {
  color: #18b1c9;
}
.booking_room_card {
  margin-bottom: 12px;
}
.monastays_breadcrumbs {
  margin-top: 32px;
  margin-bottom: 59px;
}
.link_breadcrumbs {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
}
.form_booking_personal {
  margin-top: 37px;
  width: 100%;
}
.form_line > .form_input_block:first-child {
  margin-right: 28px;
}
.form_line {
  margin-top: 20px;
}
.form_input_block {
  max-width: 308px;
  min-width: 308px;
}
.booking_area {
  margin-bottom: 48px;
}
.form_booking_personal_title {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
  font-weight: 500;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.16;
  letter-spacing: 0.3px;
  color: #325078;
  margin-bottom: 25px;
  margin-top: 40px;
}
.booking_input {
  height: 40px;
}
.booking_input:focus {
  border-color: #18b1c9;
}

.booking_personal_label {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: 0.1px;
  color: #505050;
  width: 100%;
}
.booking_area {
  width: 100%;
  border-radius: 3px;
  border: solid 1px #dddf3d;
  resize: none;
  padding: 16px 17px;
  max-height: 114px;
}

.booking_personal_label {
  display: block;
}

.booking_input,
.booking_area {
  display: block;
  padding-left: 17px;
  width: 328px;
  border-radius: 6px;
  border: 2px solid #19b1c9;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
  margin-left: 0;
  padding-right: 20px;
  -webkit-appearance: none;
}

.booking_input::placeholder,
.booking_area::placeholder {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  color: #999999;
}

.booking_personal_label {
  display: block;
}

.booking_img {
  display: block;
  max-width: 106px;
  object-fit: cover;
  border-radius: 2px;
}
.booking_wrapper {
  justify-content: space-between;
}
.breadcrumps_separator {
  display: block;
  width: 11px;
  height: 10px;
  background: url(/img/right-arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.link_breadcrumbs {
  display: block;
  color: #2b313d;
}
.breadcrumbs_wrapper {
  align-items: center;
}
.main_breadcrumbs {
  color: #18b1c9;
}
.breadcrumps_separator {
  margin-left: 14px;
  margin-right: 14px;
}
.monastays_card_container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}
.map_on {
  max-width: 70%;
}

.map_on_wrapper {
  max-width: 30%;
  min-width: 320px;
  min-height: 400px;
}
.map_on .price_card_wrapper {
  min-width: 320px;
  max-width: 50%;
}
.monastays_map_wrapper {
  position: relative;
  padding-left: 15px;
}
.monastays_map {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-top: 10px;
  left: 0;
  top: 0;
}

.search_range {
  position: absolute;
  z-index: 99;
  width: 100%;
  background: rgb(255, 255, 255);
  height: 71px;
  padding: 20px;
  min-width: 268px;
  box-shadow: rgba(200, 210, 233, 0.32) 0px 3px 24px 0px;
  border-radius: 3px;
}
.monastays_rooms_wrapper {
  position: absolute;
  background: #fff;
  z-index: 120;
  border-radius: 3px;
  box-shadow: 0 3px 24px 0 rgba(200, 210, 233, 0.32);
  top: 57px;
  padding: 34px 20px;
  min-width: 268px;
}
.monastays_rooms_wrapper a {
  display: block;
  color: #18b1c9;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-bottom: 8px;
  text-decoration: underline;
}
.monastays_rooms_wrapper input:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px #18b1c9;
  top: 0;
  left: -19px;
  background-color: #18b1c9;
  background-image: url(/img/baseline-check-24px.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.monastays_rooms_wrapper label {
  position: relative;
}
.monastays_rooms_wrapper input:not(checked) + label::after {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px #c1c8d7;
  background: #fff;
  top: 0;
  left: -19px;
}
.monastays_dates_wrapper {
  position: absolute;
  width: 100%;
  min-width: 287px;
  z-index: 120;
  top: 47px;
}

@media screen and (min-width: 768px) and (max-width: 890px) {
  .monastays_dates_wrapper {
    left: -25%;
  }
}
.monastays_rooms_wrapper input {
  visibility: hidden;
  background: transparent;
}

.location__img {
  width: 100%;
  height: 184px;
  object-fit: cover;
  margin-bottom: 15px;
}
.location__flex {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.location__flex p {
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2b313d;
}
.location__name {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: -0.1px;
  color: #2b313d;
  margin-bottom: 13px;
  padding-left: 15px;
  padding-right: 15px;
}

.gm-style .gm-style-iw-d {
  max-width: 269px !important;
  overflow: visible !important;
  /* max-height: 300px !important; */
}
.gm-style .gm-style-iw-c {
  /* max-height: 300px !important; */
  padding: 0 !important;
}
.map_form_wrapper .mi_person {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pagination_list {
  list-style: none;
  padding: 0;
  display: flex;
}

.input-range__slider {
  background: #18b1c9 !important;
  border: 1px solid #18b1c9 !important;
}

.input-range__track--active {
  background: #18b1c9 !important;
}

.monastays_search_map_form .monastays_person_wrapper {
  min-width: 228px;
}
.pagination_item {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;

  color: #325078;

  border-radius: 50%;
  line-height: 36px;
  display: block;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
}
.pagination_active {
  background: #18b1c9;
  color: #fff;
  cursor: default;
}
.pagination_wrapper {
  align-items: center;
  justify-content: center;
  margin-top: 56px;
}
.pagination_list {
  align-items: center;
  margin-bottom: 0;
}

.map_form_wrapper {
  margin-bottom: 25px;
  flex-wrap: wrap;
}
.map_check_label {
  display: block;
  width: 41px;
  height: 17px;
  background: #dae8ff;
  border-radius: 22px;
  position: relative;
}
.monastays_search_map_form .monastays_input {
  min-width: 98px;
  font-size: 12px;
  background-position: 6px 17px;
  padding-left: 25px;
}
.monastays_search_map_form .monastays_input_wrapper {
  margin-right: 14px;
  width: 213px;
}
.monastays_search_map_form .mi_location {
  background-size: 10px 12px;
  width: 100%;
  padding-right: 8px;
}
.monastays_search_map_form .mi_check {
  background-size: 15px 12px;
  /* width: 104px; */
  width: 100%;
}
.monastays_search_map_form .mi_person {
  background-size: 13px 13px;
  width: 100%;
}
.monastays_search_map_form .mi_prise {
  background-size: 15px 11px;
  /* width: 98px; */
  width: 100%;
}
.monastays_search_map_form .mi_rooms {
  background-size: 17px 13px;
  /* width: 107px; */
  width: 100%;
}
.monastays_search_map_form .mi_amenities {
  background-size: 14px 13px;
  /* width: 125px; */
  width: 100%;
}
.map_form_wrapper {
  justify-content: space-between;
  align-items: center;
}
.monastays_search_map_form .mi-drop {
  position: relative;
}
.monastays_search_map_form .mi-drop::after {
  content: '';
  width: 9px;
  height: 9px;
  background: url(/img/down-arrow.svg);
  background-size: contain;
  position: absolute;
  right: 10px;
  top: 20px;
}
.monastays_search_map_form .mi-drop_up::after {
  content: '';
  width: 9px;
  height: 9px;
  background: url(/img/down-arrow.svg);
  background-size: contain;
  position: absolute;
  right: 10px;
  top: 20px;
  transform: rotate(180deg);
}
.collapsed_map_text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #325078;
}
.map_clear {
  margin-right: 30px;
}
.map_check {
  opacity: 0;
  visibility: hidden;
}
.map_check:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: -4px;
  right: -1px;
  left: auto;
  background-image: url(/img/switcher-on.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.map_check:not(checked) + label::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: -4px;
  right: auto;
  left: -1px;
  background-image: url(/img/switcher-off.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.badge.badge-light.icon-pin {
  display: inline-block;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  font-size: 16px;
  padding: 0;
  margin-right: 3px;
}

table {
  border-collapse: separate;
}

.loaderWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 537px);
}

.popular_readmore_link {
  position: relative;
}

.DayPickerNavigation_svg__vertical {
  display: none;
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

.popular_input_wrapper .DateInput {
  width: 74% !important;
}
.popular_input_wrapper .mi_person {
  min-width: 248px;
}

.mi_location {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mi_amenities,
.mi_rooms,
.mi_prise {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-repeat: no-repeat;
  background-size: 19px 19px;
  background-position: 20px 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
}
.mi_prise {
  background-image: url(/img/price.svg);
  padding-left: 50px;
}
.mi_amenities {
  background-image: url(/img/amenities.svg);
  padding-left: 50px;
}
.mi_rooms {
  background-image: url(/img/rooms.svg);
  padding-left: 50px;
}
.SingleDatePickerInput__showClearDate {
  padding-right: 0 !important;
}
.suites_slider_wrapper {
  max-height: 165px;
  min-width: 200px;
  margin-right: 22px;
  position: relative;
}

.suites_slider_wrapper .slick-dots {
  display: none !important;
}

.CalendarMonth_table {
  border-spacing: 8px;
}

.CalendarDay__selected_span {
  background: #f5f7fa;
  color: #19b1c9 !important;
}

.CalendarDay__selected {
  background: #19b1c9 !important;
  color: #fff;
}

.DayPickerNavigation_button__default {
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
}

.CalendarDay__default {
  border: none;
}

.availability_container .CalendarDay__default:hover,
.availability_container .CalendarDay {
  cursor: default;
}

.availability_container .CalendarDay__default_2:hover {
  background: unset;
  border: unset;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  border: none;
}

.DayPickerNavigation_rightButton__horizontalDefault,
.DayPickerNavigation_rightButton__horizontalDefault:hover {
  border-left: none;
  border-bottom: none;
}

.DayPickerNavigation_leftButton__horizontalDefault,
.DayPickerNavigation_leftButton__horizontalDefault:hover {
  border-right: none;
  border-top: none;
}

.DayPickerNavigation_svg__horizontal {
  display: none;
}

.CalendarMonth_table {
  border-collapse: separate;
}

.activity_card_wrapper {
  justify-content: flex-start !important;
  flex-wrap: wrap;
}

.CalendarDay__default {
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
}

.activity_card {
  margin-right: 20px;
  margin-bottom: 20px;
}

.sc-bdVaJa.fEnBvt {
  overflow-x: auto;
}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-light .navbar-toggler-icon {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMjkgMTdIOFYxNEgyOVYxN1pNMjkgN1YxMEg0VjdIMjlaTTI5IDI0SDRWMjFIMjlWMjRaIiBmaWxsPSIjMTlCMUM5Ii8+Cjwvc3ZnPgo=')
    0 0/100%;
  width: 35px;
  height: 30px;
}

.translate_wrapper {
  align-self: flex-end;
  margin-left: auto;
  margin-right: 20px;
}

.monastays_travel_ad_header {
  display: flex;
  max-width: 1440px;
  min-height: 504px;
  max-height: 504px;
  margin-left: auto;
  margin-right: auto;
}

.travel_gallery_big_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #fff;
}
.monastays_travel_gallery,
.monastays_travel_left_sidebar {
  position: relative;
}
.monastays_travel_gallery:hover::after,
.monastays_travel_left_sidebar:hover::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  z-index: 20;
}
.monastays_travel_gallery:last-child::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
}
.monastays_travel_left_sidebar {
  width: 63%;
}

.monastays_travel_right_sidebar {
  width: 37%;
}

.travel_gallery_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #fff;
}

.monastays_travel_gallery {
  position: relative;
  height: 50%;
}

.monastays_galley_link {
  position: absolute;
  z-index: 99;
  color: #fff;
  top: 40%;
  left: 0;
  width: 100%;
  text-align: center;
  display: block;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.11);
  font-family: Montserrat, sans-serif;
  font-size: 31px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.monastays_travel_popular {
  margin-top: 30px;
}

.monastays_travel_popular_link {
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.4px;
  color: #18b1c9;
}

.monastays_travel_popular_share {
  border-radius: 2.3px;
  background-color: #f5f7fa;
  background-image: url(/img/share-alt.svg);
  background-position: 13px 10px;
  background-size: 20px;
  background-repeat: no-repeat;
  width: 113px;
  height: 42px;
  font-family: Montserrat, sans-serif;
  font-size: 15.2px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #325078;
  padding-top: 14px;
  padding-left: 44px;
}

.popular_h3 {
  font-family: Montserrat, sans-serif;
  font-size: 34px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.65;
  letter-spacing: -0.2px;
  color: #2b313d;
  margin-bottom: 5px;
  font-weight: 500;
}

.popular_location {
  margin-top: 20px;
}

.popular_map_link {
  color: #18b1c9;
  line-height: 1.3;
}

.suites_card_slide .slide_img_wrapper {
  width: 100%;
  height: 100%;
  vertical-align: middle !important;
}

.popular_card {
  border: 1px solid #f1f2f9;
  border-radius: 3px;
  text-align: center;
  min-width: 138px;
  min-height: 115px;
  padding-top: 17px;
  max-width: 138px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  margin-right: 20px;
}

.popular_card_title {
  margin-bottom: 0;
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #325078;
  margin-bottom: 5px;
}

.popular_card_count {
  margin-bottom: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.1px;
  text-align: center;
  color: #60748a;
}

.popular_card_block {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.popular_card_icon {
  width: 48px;
  height: 48px;
  object-fit: cover;
  margin-bottom: 8px;
}

.popular_h4 {
  font-family: Montserrat, sans-serif;
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.05;
  letter-spacing: 0.1px;
  color: #2b313d;
  margin-top: 35px;
  margin-bottom: 12px;
}

.review_cation_wrapper .popular_h4 {
  margin-top: 10px;
  margin-right: 10px;
}

.amenities_h4 {
  margin-top: 8px;
  margin-bottom: 35px;
}

.popular_readmore_link {
  color: #18b1c9;
}

.property_detail {
  margin-right: 33px;
  font-weight: normal;
  color: #325078;
}

.property_detail_wrapper {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: #60748a;
  margin-bottom: 7px;
}

.amenities_icon_container {
  flex-wrap: wrap;
}

.amenities_card {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 18px;
  margin-left: 0;
  margin-right: 20px;
}

.amenities_card_icon {
  width: 36px;
  height: 36px;
  object-fit: cover;
  margin-right: 10px;
}

.amenities_link_show {
  display: inline-block;
  margin-top: 23px;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #18b1c9;
  padding-right: 20px;
  position: relative;
}

.close_red {
  position: absolute;
  top: 4px;
  cursor: pointer;
  right: 0;
  display: block;
  width: 13px;
  height: 13px;
  background: url(/img/baseline-cancel-24px.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.popular_input_wrapper .mi_person {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.popular_room_title {
  position: relative;
}
.unavaliable_red {
  color: #ef2953;
  font-weight: bold;
}
.sale_line {
  position: absolute;
  z-index: 50;
  color: white;
  top: 19px;
  width: 121px;
  left: 23px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-align: center;
  background: #ef2953;
  border-radius: 3px;
}

.accommodation-mobile-collapsible {
  margin-bottom: 8px;
}

.accommodation-mobile-collapsible-open {
  margin-bottom: 24px;
}

.accommodation-mobile-collapsible-trigger {
  width: 100%;
  height: 46px;
  background: #19b1c9;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 46px;
  text-indent: 48px;
  background-image: url(/img/info-circle.svg), url(/img/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: 16px 12px, 96%, 12px;
}

.accommodation-mobile-collapsible-trigger-open {
  width: 100%;
  height: 46px;
  background: #505050;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 26px;
  margin-right: 25px;
  line-height: 46px;
  text-indent: 48px;
  background-image: url(/img/info-circle.svg), url(/img/chevron-up.svg);
  background-repeat: no-repeat;
  background-position: 16px 12px, 96%, 12px;
}

.accommodation-mobile-collapsible-content-outer {
  padding-left: 16px;
  padding-right: 16px;
}

.search-mobile-collapsible {
  margin-bottom: 8px;
  margin-top: 48px;
}

.search-mobile-collapsible-open {
  margin-bottom: 24px;
  margin-top: 48px;
}

.search-mobile-collapsible-open .search-mobile-collapsible-content-outer {
  /* overflow: visible !important; */
}

.search-mobile-collapsible .search-mobile-collapsible-content-outer {
  /* overflow: hidden !important; */
}

.search-mobile-collapsible-trigger {
  width: 100%;
  height: 46px;
  background: #19b1c9;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 46px;
  text-transform: uppercase;
  text-indent: 48px;
  background-image: url(/img/search/search.svg), url(/img/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: 16px 12px, 96%, 12px;
}

.search-mobile-collapsible-trigger-open {
  width: 100%;
  height: 46px;
  background: #505050;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 25px;
  line-height: 46px;
  text-transform: uppercase;
  text-indent: 48px;
  background-image: url(/img/search/search.svg), url(/img/chevron-up.svg);
  background-repeat: no-repeat;
  background-position: 16px 12px, 96%, 12px;
}

.search-mobile-collapsible-content-inner {
}

.collapse_link {
  position: relative;
}

.collapse_link::after {
  content: '';
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: 5px;
  right: 0;
  background-image: url(/img/arrow-down_link.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.collapse_open_link::after {
  content: '';
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: 5px;
  right: 0;
  background-image: url(/img/arrow-down_link.svg);
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}

.popular_readmore_link {
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #18b1c9;
  padding-right: 20px;
}

.popular_readmore_link:hover {
  color: #e2a915;
}

.house_link {
  margin-top: 19px;
}

.cancellation_link {
  margin-top: 23px;
}

.cancellation_h4 {
  margin-top: 26px;
}

.amenities_title {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
}

.suites_card_slide {
  width: 200px !important;
  height: 100%;
}

.suites_card_slide img {
  width: 100%;
  height: 100%;
  vertical-align: middle !important;
  object-fit: cover;
}

.suites_slider_wrapper .suites_card_slider,
.suites_slider_wrapper .slick-list,
.suites_slider_wrapper .slick-track,
.suites_slider_wrapper .slick-slide > div {
  height: 100%;
}

.suites_card {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding-right: 37px;
  width: 100%;
  margin-top: 20px;
}

.suites_info_title {
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #666ee8;
  margin-bottom: 0;
}

.suites_info_title_birusa {
  color: #18b1c9;
}

.suites_sidebar {
  padding-top: 14px;
}

.suites_sidebar {
  width: 100%;
  padding-bottom: 20px;
}

.suites_price {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: right;
  color: #222222;
  margin-bottom: 0;
}

.suites_time {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: right;
  color: #60748a;
  margin-bottom: 0;
}

.suites_title {
  margin-bottom: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 21px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: #2b313d;
  margin-bottom: 19px;
}

.suites_booking_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.suites_service {
  margin-bottom: 0;
  font-size: 12px;
  color: #222222;
  margin-bottom: 9px;
}

.suits_add {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: #18b1c9;
  border: none;
  border-radius: 3px;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 7px;
  height: 36px;
}

.suits_add_link {
  display: flex;
  align-items: center;
}

.suits_add_violet {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background-color: #666ee8;
  border: none;
  border-radius: 3px;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 7px;
}

.activity_h4 {
  margin-bottom: 40px;
}

.suites_slider_wrapper .slick-dots {
  display: none !important;
}

.suites_slider_wrapper .slick-next {
  right: 0;
  z-index: 99;
  background-image: url(/img/right-arrow_white.svg);
  background-size: cover;
  background-color: #999999;
  height: 25px;
  width: 25px;
  background-size: 20px 20px;
  background-position: 2.5px 2.5px;
  background-repeat: no-repeat;
  border-radius: 3px 0 0 3px;
}

.suites_slider_wrapper .slick-prev {
  background-image: url(/img/left-arrow_white.svg);
  background-size: cover;
  left: 0;
  z-index: 99;
  background-color: #999999;
  height: 25px;
  width: 25px;
  background-size: 20px 20px;
  background-position: 2.5px 2.5px;
  background-repeat: no-repeat;
  border-radius: 0px 3px 3px 0;
}

.suits_count {
  border: 2px solid #18b1c9;
  color: #60748a;
  background: #fff;
  padding: 5px 15px;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 31px;
  background-image: url(/img/down-arrow.svg);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 33px center;
}

.suites_service-icon {
  /* width: 15px;
  height: 15px; */
  object-fit: cover;
  margin-right: 10px;
}

.review_avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 16px;
  margin-left: 20px;
  border-radius: 50%;
  object-fit: cover;
}

.review_comment {
  padding-left: 76px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #222222;
}

.review_username {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 0;
}

.review_userpublish {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #949fb7;
  margin-top: 2px;
  margin-bottom: 18px;
}

.popular_input_wrapper {
  margin-bottom: 13px;
  text-align: left;
}
.popular_input_wrapper .monastays_input_wrapper {
  margin-right: 0;
}
.popular_contact_link {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #18b1c9;
  text-decoration: underline;
}

.popular_form_info {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: center;
  color: #60748a;
}

.popular_form_search {
  padding: 20px 20px;
  border-radius: 5px;
  border: solid 1px #f1f2f9;
  /* display: none; */
}

.popular_instructions_title {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #596172;
  margin-bottom: 5px;
}

.popular_form_title {
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b313d;
}

.popular_input {
  width: 100%;
}

.popular_search_btn {
  padding: 10px 0;
  border-radius: 3px;
  width: 100%;
  display: block;
  text-align: center;
}

.popular_search_btn:hover,
.popular_search_btn:active,
.popular_search_btn:focus {
  color: #fff;
  text-decoration: none;
}

.booking_btn {
  max-width: 224px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 34px;
}

.popular_instructions_wrapper {
  border-radius: 3px;
  padding: 20px 38px;
  margin-top: 20px;
  border-radius: 5px;
  border: solid 1px #f1f2f9;
}

.popular_instructions_info {
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #949fb7;
}

.popular_check_wrapper {
  margin-top: 25px;
  border-bottom: solid 1px #f1f2f9;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #505050;
}

.popular_room_title {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #325078;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.2px;
  padding-right: 20px;
}

.popular_room_subtitle {
  margin-bottom: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.2px;
  color: #60748a;
}
.unavailable_red {
  color: #ef2953;
  font-weight: bold;
}
.popular_room_wrapper {
  border-bottom: solid 1px #cccccc;
  padding-bottom: 20px;
}

.popular_room {
  margin-top: 15px;
  justify-content: space-between;
}

.total_title {
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: center;
  color: #505050;
}

.total_days {
  margin-bottom: 0;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0.2px;
  color: #18b1c9;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
}

.total_price {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.1px;
  text-align: right;
  color: #505050;
  margin-left: 5px;
}

.total_subtitle {
  margin-bottom: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: right;
  color: #60748a;
}

.popular_form_info_wrapper {
  display: flex;
  align-items: flex-start;
}

.popular_total_wrapper {
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #cccccc;
}

.popular_check_price {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0.2px;
  color: #999999;
}

.popular_form_info_text {
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.1px;
  color: #60748a;
}

.popular_form_info_icon {
  width: 12px;
  height: 12px;
  opacity: 0.5;
  margin-right: 5px;
}

.popular_form_info_wrapper {
  margin-top: 15px;
  padding-bottom: 18px;
}

.popular_check_label {
  font-family: Montserrat;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #222222;
  position: relative;
  margin-left: 10px;
}

.agree_check_input {
  visibility: hidden;
}

.agree_check_input:not(:checked) + label::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: solid 2px #19b1c9;
  background: #fff;
  top: -5px;
  left: -31px;
}
.agree_check_input:not(:checked):hover + label::after {
  border: solid 2px #e2a915;
}

.agree_check_input:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  top: -5px;
  left: -31px;
  background-color: #18b1c9;
  background-size: contain;
}
.agree_check_input:checked:hover + label::after {
  background-color: #e2a915;
}

.agree_check_input {
  margin-left: 8px;
}

.popular_check_input {
  visibility: hidden;
}

.popular_check_input:not(:checked) + label::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: solid 2px #19b1c9;
  background: #fff;
  top: 0;
  left: -31px;
}
.popular_check_input:not(:checked):hover + label::after {
  border: solid 2px #e2a915;
}

.popular_check_input:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  top: 0;
  left: -31px;
  background-color: #18b1c9;
  background-size: contain;
}
.popular_check_input:checked:hover + label::after {
  background-color: #e2a915;
}

.popular_check_input {
  margin-left: 8px;
}

.activity_card:nth-child(4) {
  /*margin-right: 0;*/
}

.popular_header_container {
  flex-wrap: wrap;
}

.map_container {
  width: 100%;
}

.red_border .monastays_input {
  border-color: #ef2953;
}

.on_red button {
  -webkit-filter: invert(40%) grayscale(100%) brightness(70%) sepia(100%)
    hue-rotate(-50deg) saturate(400%) contrast(2);
  filter: grayscale(100%) brightness(70%) sepia(100%) hue-rotate(-50deg)
    saturate(600%) contrast(0.8);
}

.on_red .SingleDatePickerInput__withBorder {
  border-color: #ef2953;
}

.activity_card {
  min-width: 165px;
  min-height: 154px;
  border-radius: 3px;
  border: solid 1px #e4e5ec;
  background-color: #ffffff;
  padding-top: 16px;
  padding-left: 14px;
  padding-right: 14px;
  max-width: 165px;
  padding-bottom: 14px;
}

.activity_title {
  margin-top: 16px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: #325078;
  margin-bottom: 11px;
}

.activity_service {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 350;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.77;
  letter-spacing: normal;
  color: #222222;
  margin-bottom: 2px;
}

.activity_header {
  align-items: center;
}

.activity_price {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2b313d;
}

/* start fix */

span.badge-light {
  background: transparent;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

.navbar-light .navbar-nav .monastays_nav_link {
  display: flex;
  align-items: center;
}

/* end fix */

.SingleDatePickerInput,
.SingleDatePicker {
  width: 100%;
}

.SingleDatePickerInput_calendarIcon {
  margin: 0;
}
.DateRangePickerInput_arrow {
  display: none !important;
}

.monastays_ad_header {
  background: url(/img/home-main.jpg) 0 50% / cover no-repeat;
  padding-top: 150px;
  padding-bottom: 160px;
  max-height: 316px;
  /* background-size: 80% 80%; */
}

.monastays_h1 {
  text-shadow: 0 2px 13px rgba(0, 0, 0, 0.18);
  font-family: Playfair Display;
  font-size: 61px;
  font-weight: 900;
  line-height: 1.16;
  letter-spacing: 2px;
  margin-bottom: 50px;
}

.monastays_h1,
.monastays_title {
  font-style: normal;
  font-stretch: normal;
  color: #fff;
  text-transform: uppercase;
}

.monastays_title {
  text-shadow: 0 2px 13px rgba(0, 0, 0, 0.22);
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1.8px;
}

.monastays_h4,
.monastays_title {
  font-family: Montserrat, sans-serif;
  line-height: normal;
}

.monastays_h4 {
  font-size: 21px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #325078;
}

.monastays_h5 {
  font-family: Montserrat, sans-serif;
  line-height: normal;
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #325078;
}

.map_h4 {
  margin-bottom: 27px;
}

.monastays_search_block {
  padding-top: 28px;
}

.monastays_search_form {
  width: 100%;
  margin-top: 22px;
  margin-bottom: 25px;
}

.monastays_input {
  border-radius: 6px;
  border: 1px solid #dddfed;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
  padding-left: 45px;
  margin-left: 0;
  padding-right: 20px;
  -webkit-appearance: none;
}

.monastays_input::-webkit-input-placeholder {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #60748a;
}

.monastays_input:-ms-input-placeholder {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #60748a;
}

.monastays_input::-ms-input-placeholder {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #60748a;
}

.monastays_input::-moz-placeholder {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #60748a;
}

.monastays_input::placeholder {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #60748a;
}

.location__clearBtn,
.location__clearBtn:active,
.location__clearBtn:focus {
  text-align: center;
  position: absolute;
  right: 0px;
  padding: 10px;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.location__autocompleteContainer {
  border: #f0fff0;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 6px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 7%);
  border-radius: 6px;
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 99;
  font-family: Montserrat;
}

.location__suggestionItem {
  font-size: 13px;
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.location__suggestionItem_active {
  background-color: #fafafa;
}

.mi_location {
  background-image: url(/img/search/searchbar/location.svg);
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: 10px 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #fff;
  width: 100%;
  height: 40px;
}

.mi_location::placeholder {
  font-family: Montserrat;
  font-size: 12px;
}

.mi_check {
  background-image: url(/img/search/searchbar/calendar-left.svg);
  padding-left: 55px;
}

.mi_check {
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: 12px 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #fff;
  height: 40px;
}

.mi_person {
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 14px 10px;
  height: 40px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #fff;
  background-image: url(/img/search/searchbar/person.svg);
  color: #999999;
  padding-left: 40px;
}

.mi_person::placeholder {
  color: #999999;
}

.mi-drop {
  position: relative;
  text-overflow: ellipsis;
}

.monastays_input_wrapper {
  position: relative;
}

.c_day {
  padding: 0 8px;
  font-size: 12px;
  line-height: normal;
}

.c_day,
.c_number {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #495766;
}

.c_number {
  padding: 5px;
  border-radius: 50%;
  display: block;
  width: 30px;
  height: 30px;
  margin: 4px;
  font-size: 14px;
}

.calendar_numbers {
  display: flex;
}

.c_number_later {
  color: #babfc4;
}

.c_active {
  color: #fff;
  background: #18b1c9;
}

.c_ranged {
  color: #18b1c9;
  background: #f5f7fa;
}

.calendar_header {
  display: flex;
  position: relative;
  justify-content: center;
}

.calendar_next,
.calendar_prev {
  position: absolute;
}

.calendar_prev {
  left: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.calendar_next,
.calendar_prev {
  background-color: transparent;
  width: 10px;
  height: 10px;
  padding: 0;
  opacity: 0.8;
  border: 1px solid #979797;
  border-right: none;
  border-bottom: none;
}

.calendar_next {
  right: 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.calendar_clear {
  color: #495766;
}

.calendar_apply,
.calendar_clear {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background: 0 0;
  border: none;
}

.calendar_apply {
  color: #19b1c9;
  margin-left: auto;
}

.monastays_person_wrapper {
  justify-content: space-between;
  position: absolute;
  background: #fff;
  z-index: 120;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 7%);
  top: 44px;
  font-family: Montserrat;
  font-size: 12px;
  padding: 16px;
  width: 100%;
}

.person_button:disabled {
  color: #495766;
}

.person_button,
.person_button:disabled {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: block;
  background: 0 0;
  border: none;
}

.person_button {
  color: #19b1c9;
}

.person_line {
  align-items: flex-start;
  margin-bottom: 8px;
}

.person_button_wrapper,
.person_line {
  display: flex;
  justify-content: space-between;
}

.person_minus,
.person_plus {
  background: #f5f7fa;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  color: #19b1c9;
  font-size: 16px;
  border: none;
}

.person_minus:disabled {
  background: #f3f3f3;
  color: silver;
}

.person_count {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495766;
}

.person_count_wrapper {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.person_title {
  width: 75px;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #325078;
  margin-bottom: 0;
}

.person_subtitle {
  font-size: 11px;
  color: #60748a;
}

.mounth_year,
.person_subtitle {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.mounth_year {
  font-size: 16px;
  text-align: center;
  color: #325078;
  margin-bottom: 35px;
}

.calendar_days {
  margin-bottom: 15px;
}

.calendar_apply,
.calendar_clear {
  margin-top: 30px;
  display: block;
}

.monastays_search_btn {
  min-width: 182px;
  border-radius: 6px;
  background-color: #18b1c9;
  border: 1px solid #18b1c9;
  color: #fff;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.1px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.monastays_search_btn:hover {
  background: #127d8e;
}

.monastays_question_link {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #18b1c9;
  text-decoration: underline;
}

.monastays_question_link:hover {
  color: #127d8e;
}

.monastays_line {
  background: #dddfed;
  margin-top: 30px;
  margin-bottom: 40px;
}

.review_cation_wrapper {
  margin-bottom: 26px;
}

.popular_search_btn {
  text-transform: inherit;
}

.house_line {
  margin-top: 37px;
  margin-bottom: 12px;
}

.house_h4 {
  margin-bottom: 22px;
}

.popular_info_text {
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: #60748a;
  margin-bottom: 0;
}

.calendar_line {
  margin-bottom: 21px;
}

.activity_line {
  margin-top: 43px;
  margin-bottom: 28px;
}

.suites_line {
  margin-top: 60px;
  margin-bottom: 14px;
}

.amenities_line {
  margin-top: 32px;
  margin-bottom: 10px;
}

.popular_line {
  margin-top: 16px;
}

.monastays_h3 {
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #60748a;
  margin-bottom: 20px;
}

.monastays_h3_darken {
  color: #2b313d;
}

.price_card_img img {
  width: 100%;
  min-height: 211px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 234px;
}

.price_card_img {
  min-height: 211px;
}

.monastays_show_link {
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: #60748a;
  padding-right: 20px;
  position: relative;
  background-image: url(/img/right-arrow.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 100%;
}

.monastays_show_link,
.price_service_title {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-stretch: normal;
}

.price_service_title {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.3px;
}

.ps_ocean {
  color: #18b1c9;
}

.ps_indigo {
  color: #325078;
}

.ps_violet {
  color: #666ee8;
}

.ps_rating_star {
  width: 10px;
  height: 10px;
  display: block;
  margin-right: 3px;
  cursor: pointer;
}

.price_service_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
}

.price_service_rating {
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.ps_rating_indigo {
  background: url(/img/rating_indigo.svg) 50% / contain no-repeat;
}

.ps_rating_gray {
  background: url(/img/rating_gray.svg) 50% / contain no-repeat;
}

.ps_rating_violet {
  background: url(/img/rating_violet.svg) 50% / contain no-repeat;
}

.ps_rating_ocean {
  background: url(/img/rating_ocean.svg) 50% / contain no-repeat;
}

.dv-star-rating-star {
  margin-bottom: 0;
}

.price_card_title {
  font-size: 21px;
  line-height: 1.05;
  letter-spacing: -0.6px;
  color: #2b313d;
  margin-top: 15px;
}

.price_card_location,
.price_card_title {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  margin-bottom: 13px;
}

.price_card_location {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #60748a;
  padding-left: 20px;
}

.price_card_price {
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #2b313d;
  margin-bottom: 21px;
}

.price_card_price,
.price_info_icons {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-stretch: normal;
}

.price_info_icons {
  font-size: 12px;
  font-weight: 400;
  line-height: 0.92;
  letter-spacing: -0.1px;
  color: #60748a;
  margin-bottom: 22px;
  padding-top: 11px;
  margin-right: 8px;
}

.travel_info_icons {
  padding-top: 22px;
}

.price_info_icon {
  max-width: 15px;
  margin-right: 5px;
}

.price_card_subtitle {
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.27;
  letter-spacing: 0.5px;
  color: #60748a;
  text-transform: uppercase;
}

.price_card_info {
  display: flex;
}

.historical {
  background: url(/img/history.svg) 0 100%/17px 21px no-repeat;
  padding-left: 18px;
  margin-right: 10px;
}

.length {
  background: url(/img/sea.svg) 0 100%/19px 16px no-repeat;
  margin-right: 12px;
}

.eating,
.length {
  padding-left: 24px;
}

.eating {
  background: url(/img/wine.svg) 0 100%/19px 19px no-repeat;
  margin-right: 10px;
}

.hiking {
  background: url(/img/hiking.svg) 0 100%/19px 19px no-repeat;
  padding-left: 24px;
}

:focus {
  outline: 0 !important;
}

.price_card_show_btn {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #325078;
  padding-right: 20px;
  position: relative;
  background-image: url(/img/right-arrow.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 100%;
}

.monastays_card_wrapper {
  padding-bottom: 20px;
}

.price_card_link_wrapper {
  margin-top: 50px;
  margin-bottom: 90px;
}

.monstays_comments_wrapper {
  padding-top: 58px;
  background: #f5f7fa;
  padding-bottom: 100px;
}

.monastays_h2 {
  font-family: Montserrat, sans-serif;
  font-size: 42px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: 0.3px;
  color: #2b313d;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 90px;
}

.monastays_h2:after {
  content: '';
  display: block;
  width: 70px;
  position: absolute;
  height: 2px;
  background: #18b1c9;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
}

.monastays_underline:after {
  content: '';
  display: block;
  width: 70px;
  position: absolute;
  height: 2px;
  background: #18b1c9;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
}

.comments_card {
  background-color: #fff;
  min-height: 362px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #f4f5fa;
  margin-bottom: 20px;
  padding: 100px 44px 44px;
}

.comments_card_ocean {
  background-image: url(/img/quote-green.svg);
}

.comments_card_ocean,
.comments_card_violet {
  background-repeat: no-repeat;
  background-size: 44px;
  background-position: 44px 25px;
}

.comments_card_violet {
  background-image: url(/img/quote-purple.svg);
}

.comments_card_indigo {
  background-image: url(/img/quote-blue.svg);
  background-repeat: no-repeat;
  background-size: 44px;
  background-position: 44px 25px;
}

.monastays_comment {
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: 0.3px;
  color: #2b313d;
}

.commentator_name {
  font-size: 16px;
  font-weight: 600;
  color: #325078;
  margin-bottom: 7px;
}

.commentator_location,
.commentator_name {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
}

.commentator_location {
  font-size: 14px;
  font-weight: 400;
  color: #60748a;
  margin-bottom: 0;
}

.monastays_commentator {
  display: flex;
  margin-top: 20px;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.commentator_avatar {
  width: 67px;
  height: 67px;
  margin-right: 15px;
}

.commentator_avatar img {
  width: 67px;
  height: 67px;
  border-radius: 50%;
  /* start fix */
  object-fit: cover;
  /* end fix */
}

.monastays_about_wrapper {
  padding-top: 70px;
}

.monastays_about_logo {
  width: 66px;
  margin-left: auto;
  margin-right: auto;
  height: 56px;
  margin-bottom: 20px;
}

.monastays_about_logo img {
  width: 100%;
}

.monastays_about_title_logo {
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #18b1c9;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.monastays_about_info,
.monastays_about_title_logo {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
}

.monastays_about_info {
  font-weight: 300;
  line-height: 1.69;
  letter-spacing: normal;
  color: #495766;
  margin-bottom: 50px;
  /*margin-top: -50px;*/
}

.monastays_christmas_info {
  font-weight: 300;
  line-height: 1.69;
  letter-spacing: 0.6px;
  color: #495766;
  margin-bottom: 40px;
}

.monastays_about_title {
  font-size: 21px;
  font-weight: 600;
  line-height: normal;
  color: #325078;
  /*margin-bottom: 38px;*/
}

.monastays_about_card_text,
.monastays_about_title {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.monastays_about_card_text {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.75;
  color: #495766;
}

.monastays_about_card {
  max-width: 373px;
}

.mac_l {
  margin-left: 145px;
}

.mac_r {
  margin-left: 80px;
  max-width: 335px;
}

.monastays_about_autor {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #2b313d;
  padding-left: 41px;
  position: relative;
  margin-top: 32px;
  text-transform: uppercase;
}

.monastays_about_autor:after {
  content: '';
  width: 23px;
  height: 2px;
  background: #2b313d;
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  border-radius: 2px;
}

.monastays_signature {
  margin-top: 22px;
  margin-left: 36px;
  width: 120px;
  height: 70px;
}

.monastays_signature img {
  width: 100%;
}

.translate_link {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #325078;
  position: relative;
}

.translate_link,
.translate_link:hover {
  text-decoration: none;
}

.translate_link .badge {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.translate_link:after {
  width: 10px;
  height: 10px;
  border: none;
  background: url(/img/down-arrow.svg) 0 0 / contain;
  top: 5px;
  position: absolute;
  content: '';
  right: -16px;
}

.translate_container {
  position: relative;
  cursor: pointer;
}

.translate_dropdown {
  position: absolute;
  top: -20px;
  width: 151px;
  box-shadow: 0 5px 24px 0 rgba(200, 210, 233, 0.24);
  background-color: #fff;
  padding-top: 29px;
  padding-left: 20px;
  border-radius: 5px;
  z-index: 99;
}

.translate_dropdown:after {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -13px;
  right: 25px;
}

.translate_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 60px;
  padding-right: 10px;
}

.footer_translate_wrapper {
  margin-top: 13px;
  display: flex;
  justify-content: flex-end;
}

.nav-item {
  margin-right: 8px;
}

.no_arrow {
  display: block;
  margin-bottom: 18px;
}

.no_arrow:after {
  display: none;
}

.footer_dropdown {
  top: 30px;
}

.navbar-light .navbar-nav .monastays_nav_link {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #19b1c9;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.avatar_login {
  width: 38px;
  height: 38px;
  cursor: pointer;
  margin-left: 34px;
  position: relative;
}

.avatar_login img {
  width: 38px;
  height: 38px;
  object-fit: contain;
  border-radius: 50%;
}

.user_settings {
  z-index: 99;
  position: absolute;
  background: #fff;
  top: 70px;
  right: -25px;
  width: 154px;
  height: 143.6px;
  border-radius: 5px;
  box-shadow: 0 5px 24px 0 rgba(200, 210, 233, 0.24);
  padding: 16px;
}

.user_settings:after {
  content: '';
  background: url(/img/arrow-point-to-up.svg) 50% / contain no-repeat;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -13px;
  right: 35px;
}

.user_link img {
  width: 14px;
  height: 14px;
  border-radius: 0;
}

.user_link .badge {
  margin-right: 10px;
}

.user_link {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  margin-bottom: 20px;
  color: #495766;
}

.user_active {
  color: #18b1c9;
}

.manastays_social_wrapper {
  display: flex;
  justify-content: flex-end;
}

.social_link {
  margin-left: 34px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.social_link:hover {
  opacity: 0.7;
}

.footer_line {
  margin-top: 60px;
  margin-bottom: 25px;
}

.monastays_text {
  text-align: right;
}

.monastays_footer_link {
  color: #18b1c9;
}

.monastays_copy {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #60748a;
}

.monastays_footer {
  padding-bottom: 62px;
}

.price_card .slick-next,
.price_card .slick-prev,
.mobile_slider .slick-next,
.mobile_slider .slick-prev {
  position: absolute;
  top: 50%;
}

.price_card .slick-next:before,
.price_card .slick-prev:before,
.mobile_slider .slick-next:before,
.mobile_slider .slick-prev:before {
  display: none;
}

.price_card .slick-dots {
  position: absolute;
  bottom: 14px;
  margin: 0;
  list-style-type: none;
  padding: 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.price_card .slick-dots button {
  font-size: 0;
  width: 6px;
  height: 6px;
  padding: 0;
  border: none;
  background: #fff;
  opacity: 0.65;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
}

.price_card .slick-dots button:before {
  display: none;
}

.price_card .slick-next,
.mobile_slider .slick-next {
  background-image: url(/img/right-arrow_white.svg);
  right: 0;
}

.price_card .slick-next,
.price_card .slick-prev,
.mobile_slider .slick-next,
.mobile_slider .slick-prev {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  border: none;
  color: transparent;
  z-index: 99;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 100%;
  width: 15%;
  background-size: 20px;
}

.price_card .slick-prev,
.mobile_slider .slick-prev {
  background-image: url(/img/left-arrow_white.svg);
  left: 0;
}

.price_card .slick-active button {
  width: 9px;
  height: 9px;
  opacity: 0.95;
}

.price_card_location {
  background-image: url(/img/pin-copy-6.svg);
  background-repeat: no-repeat;
  margin-left: 0;
}
.monastays_search_form .monastays_input.mi_person {
  width: 265px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monastays_search_form #endDate {
  width: 129px;
}
.popular_activity_text {
  margin-bottom: 20px;
}
.popular_search_btn_gray {
  background-color: #bac9da !important;
  border-color: #bac9da;
}

.popular_search_btn_gray:hover {
  background-color: #9eabb9;
}

.DateRangePickerInput {
  display: block;
  border-radius: 6px;
  font-family: Montserrat;
}

@media screen and (max-width: 1199px) {
  .suites_price_block {
    width: 49px;
  }
  .suites_price_block > span {
    font-size: 0;
  }
  .search_map_sline {
    margin-top: 0;
  }
  .monastays_search_map_form .monastays_input_wrapper {
    width: 33%;
  }
  .form.monastays_search_map_form {
    max-width: 100%;
  }
  .form_input_block {
    min-width: 200px;
  }
  .map_on_wrapper {
    min-width: 250px;
  }

  .monastays_search_form .monastays_input.mi_person {
    width: 100%;
  }
  .monastays_search_form #endDate {
    width: 438px;
    /*! max-width: 475px; */
  }
  .popular_card {
    margin-bottom: 20px;
  }
  .popular_card_block {
    flex-wrap: wrap;
  }
  /* start fix */
  .price_service_wrapper {
    height: 42px;
  }
  .price_service_rating {
    min-width: 100px;
  }

  .DateRangePicker {
    display: block;
    margin-bottom: 20px;
  }
  .DateRangePickerInput {
    display: block;
  }

  .monastays_input_wrapper {
    margin-right: 0;
  }
  .monastays_search_form {
    flex-direction: column;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .popular_card_info {
    flex-direction: row;
  }
  .popular_form_search .CalendarDay__default {
    border-radius: 50%;
    width: 22px !important;
    height: 22px !important;
  }
  .popular_form_search .DayPicker_weekHeader_li {
    width: 30px !important;
  }
  .popular_form_search .DayPicker_transitionContainer {
    width: 265px !important;
  }
  .popular_form_search .DayPicker_focusRegion {
    width: 265px !important;
  }
}

@media screen and (max-width: 991px) {
  .suites_price_block {
    width: 120px;
  }
  .suites_price_block > span {
    font-size: 12px;
  }
  .monastays_search_map_form .monastays_input_wrapper {
    width: 100%;
  }
  .search_map_fline,
  .search_map_sline {
    flex-wrap: wrap;
  }
  .booking_search {
    margin-top: 20px;
    max-width: 100%;
    margin-right: auto;
  }
  .form_input_block {
    min-width: 332px;
  }
  .map_on_wrapper {
    margin-bottom: 20px;
  }
  .map_on {
    max-width: 100%;
  }
  .map_on_wrapper {
    max-width: 100%;
    order: -1;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .monastays_search_map_form > div:first-child {
    width: 100%;
    /* margin-right: 14px; */
  }
  .monastays_search_map_form {
    flex-wrap: wrap;
  }
  .monastays_search_map_form .monastays_input {
    width: 100%;
  }
  .monastays_search_map_form .monastays_input_wrapper {
    width: 100%;
  }
  .monastays_search_form #endDate {
    width: 526px;
  }
  .translate_wrapper {
    width: 84px;
    text-align: left;
  }
  .monastays_travel_popular_share {
    margin-top: -13px;
  }
  .suites_sidebar {
    margin-left: 14px;
  }
  .monastays_travel_right_sidebar {
    display: none;
  }
  .monastays_travel_left_sidebar {
    position: relative;
    width: 100%;
  }
  .monastays_travel_left_sidebar::after {
    content: '';
    display: block;
    border: none;
    border-bottom: dotted;
    border-color: #fff;
    border-width: 20px;
    height: 10px;
    width: 200px;
    position: absolute;
    bottom: 20px;
    z-index: 99;
    left: calc(50% - 100px);
  }
  .suites_card_slider {
    margin-right: 0;
  }
  .suites_card_slide {
    width: 100% !important;
    vertical-align: middle !important;
    margin-right: 0;
  }
  .activity_card_wrapper {
    flex-wrap: wrap;
    align-items: stretch;
  }
  .activity_card {
    margin-bottom: 20px;
    margin-right: 14px;
  }
  .popular_card {
    max-width: 100%;
    width: 300px;
    min-width: 300px;
    margin-bottom: 20px;
  }
  /* start fix */
  .navbar-expand-lg .navbar-nav {
    align-items: flex-start;
  }
  .avatar_login {
    margin-bottom: 18px;
  }
  .footer_translate_wrapper .translate_dropdown {
    position: relative;
    left: auto;
    top: 10px;
  }
  .footer_translate_wrapper > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
  .monastays_person_wrapper {
    width: 100%;
  }
  .monastays_search_form {
    max-width: 100%;
  }
  div.navbar-collapse {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .user_settings {
    top: 40px;
    right: auto;
  }
  .translate_dropdown {
    position: static;
  }
  .translate_link .badge {
    padding-left: 0;
  }
  /* end fix */
  .avatar_login,
  .mac_l,
  .mac_r {
    margin-left: 0;
  }
  .translate_dropdown,
  .user_settings {
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  .popular_h3 {
    line-height: normal;
  }
  .booking_edit_btn_wrapper {
    flex-wrap: wrap;
  }
  .booking_edit_btn_wrapper .booking_cancel_btn,
  .booking_edit_btn_wrapper .booking_apply_btn {
    margin-bottom: 20px;
    width: 100%;
  }
  .edit_dates_wrapper {
    flex-wrap: wrap;
  }
  .edit_dates_wrapper .popular_input_wrapper {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }

  .booking_btn_wrapper .booking_pay_btn {
    width: 328px;
    margin-bottom: 20px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .form_line {
    margin-top: 0;
  }
  .form_input_block {
    margin-top: 20px;
  }
  .form_line {
    flex-wrap: wrap;
  }
  .form_input_block {
    min-width: 100%;
  }
  .map_on .price_card_wrapper {
    max-width: 100%;
  }

  .monastays_search_form #endDate {
    width: 350px;
  }
  .DayPickerNavigation_prevButton__verticalDefault,
  .DayPickerNavigation_prevButton__verticalDefault:hover {
    border-top: 0;
    border-right: 0;
  }
  .DayPickerNavigation_nextButton__verticalDefault,
  .DayPickerNavigation_nextButton__verticalDefault:hover {
    border-bottom: 0;
  }
  .DayPickerNavigation_button__verticalDefault {
    box-shadow: none;
  }
  .DayPickerNavigation__verticalDefault {
    top: 25px;
    display: flex;
    justify-content: space-around;
  }
  .DayPicker_transitionContainer__vertical {
    height: 600px !important;
  }
  .activity_card_wrapper {
    justify-content: center !important;
  }
  .popular_card_block {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .suites_slider_wrapper {
    max-width: 100% !important;
    height: 200px;
    position: relative;
  }
  .suites_card_slide {
    width: 100%;
    height: 100%;
  }
  .suites_card_slide img {
    object-fit: cover;
  }
  /* start fix */
  .suites_card {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  .suites_sidebar {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .monastays_signature {
    margin-bottom: 38px;
  }
  .social_link {
    margin-left: 0;
    margin-right: 34px;
  }
  .footer_translate_wrapper > div {
    align-items: flex-start;
  }
  .footer_translate_wrapper .translate_dropdown {
    left: 0;
  }
  .translate_dropdown::after {
    right: auto;
    left: 55px;
  }
  /* end fix */
  .monastays_calendar_wrapper {
    flex-direction: column;
    width: 310px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .monastays_calendar_right {
    margin-top: 20px;
  }
  .calendar_clear {
    position: absolute;
    bottom: 34px;
  }
  .monastays_h1 {
    font-size: 40px;
  }
  .monastays_h2 {
    font-size: 32px;
  }
  .translate_container {
    margin-left: 0 !important;
  }
  .manastays_social_wrapper,
  .translate_container {
    justify-content: flex-start;
  }
  .monastays_text {
    -moz-text-align-last: left;
    text-align-last: left;
  }
  .suites_slider_wrapper {
    margin-right: 0;
  }
}
@media screen and (max-width: 540px) {
  .suites_icon_wrapper {
    width: 100%;
  }
  .popular_h3 {
    line-height: normal;
    font-size: 28px;
  }
  .edit_booking_wrapper .popular_h3 {
    width: 100%;
  }
  .booking_edit_btn {
    margin-top: 20px;
  }

  .monastays_search_form #endDate {
    width: 300px;
  }
}
@media screen and (max-width: 500px) {
  .monastays_search_form #endDate {
    width: 280px;
  }
}
@media screen and (max-width: 475px) {
  .suites_title_block {
    max-width: 165px;
  }
  .suites_price_block {
    width: 110px;
  }
  .suites_price_block > span {
    font-size: 10px;
  }
  .booking_range_card {
    margin-left: auto;
    margin-right: auto;
  }
  .booking_range_wrapper {
    flex-direction: column;
    align-items: center;
  }
  .booking_range_arrow {
    transform: rotate(90deg);
  }

  .monastays_booking_first .popular_h3 {
    line-height: 1.1;
  }
  .link_breadcrumbs {
    font-size: 12px;
  }
  .monastays_search_form #endDate {
    width: 250px;
  }
}
@media screen and (max-width: 446px) {
  .monastays_search_form #endDate {
    width: 230px;
  }
}
@media screen and (max-width: 431px) {
  .navbar-light .navbar-toggler {
    padding: 0;
  }
  .navbar-brand {
    margin-right: 0;
  }
  .translate_wrapper {
    margin-right: 10px;
  }
}
@media screen and (max-width: 428px) {
  .suites_price_block > span {
    font-size: 0;
  }
  .monastays_search_form #endDate {
    width: 220px;
  }
}
@media screen and (max-width: 416px) {
  .suites_title_block {
    max-width: 150px;
  }
  .monastays_search_form #endDate {
    width: 200px;
  }
}
@media screen and (max-width: 410px) {
  a.navbar-brand img {
    width: 148px;
  }
  .no_arrow {
    margin-bottom: 12px;
  }
  .popular_h3 {
    font-size: 32px;
  }
}

@media screen and (max-width: 400px) {
  .activity_card_wrapper {
    flex-direction: column;
  }
  .map_filter_wrapper {
    flex-wrap: wrap;
  }
  .show_filter,
  .map_clear,
  .collapsed_map {
    margin-bottom: 10px;
  }
  .monastays_search_form #endDate {
    width: 180px;
  }
  .activity_card {
    margin-right: 0;
  }
  .suites_price_block {
    width: auto;
    font-size: 12px;
  }
}
@media screen and (max-width: 374px) {
  .suites_title_block {
    max-width: 185px;
  }
  .monastays_search_form #endDate {
    width: 170px;
  }
}
@media screen and (max-width: 367px) {
  .monastays_search_form #endDate {
    width: 160px;
  }
  .monastays_h1 {
    font-size: 38px;
  }
  .monastays_h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 350px) {
  .suites_price_block {
    width: auto;
    font-size: 0;
  }
  .monastays_search_form #endDate {
    width: 140px;
  }
}
@media screen and (max-width: 332px) {
  .booking_check .popular_check_label {
    font-size: 12px;
  }

  .monastays_search_form #endDate {
    width: 120px;
  }
}

.DayPickerNavigation_leftButton__horizontalDefault {
  border: 3px solid #e4e7e7;
  border-top: none;
  border-right: none;
}

.DayPickerNavigation_rightButton__horizontalDefault {
  border: 3px solid #e4e7e7;
  border-left: none;
  border-bottom: none;
}

.DayPickerNavigation_leftButton__horizontalDefault:hover {
  border: 4px solid #c4c4c4;
  border-top: none;
  border-right: none;
}

.DayPickerNavigation_rightButton__horizontalDefault:hover {
  border: 4px solid #c4c4c4;
  border-left: none;
  border-bottom: none;
}

/*# sourceMappingURL=main.3850993b.chunk.css.map */
